import { TextSkeleton } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { Box, type SxProps, type Theme } from "@mui/material";

export interface HourlyPayLabelProps {
  /**
   * The formatted hourly pay for the shift.
   */
  hourlyPay: string | undefined;
  /**
   * Whether the pay data is being fetched.
   */
  isLoading?: boolean;
  /**
   * Custom styles to apply to the component.
   */
  sx?: SxProps<Theme>;
  /**
   * The size of the label.
   * Large corresponds to the h4 size for label and body1 for the /hr.
   * Medium corresponds to the h6 size for label and caption for the /hr.
   */
  size?: "large" | "medium";
}

// TODO: Move to cbh-core as fast follow up to https://linear.app/clipboardhealth/issue/SPRTA-1362/update-shift-details-bottom-sheet
export function HourlyPayLabel(props: HourlyPayLabelProps) {
  const { hourlyPay, isLoading, sx, size = "medium" } = props;

  const payLabelVariant = size === "large" ? "h4" : "h6";
  const hourLabel = size === "large" ? "body1" : "caption";

  if (isLoading) {
    return <TextSkeleton variant={payLabelVariant} width="7ch" sx={sx} />;
  }

  return (
    <Text semibold variant={payLabelVariant} sx={sx}>
      {hourlyPay}{" "}
      <Box
        component="span"
        sx={(theme) => ({
          fontSize: theme.typography[hourLabel].fontSize,
          lineHeight: theme.typography[hourLabel].lineHeight,
        })}
      >
        /hr
      </Box>
    </Text>
  );
}
