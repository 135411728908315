import { getTheme } from "@clipboard-health/ui-theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ModalRoute } from "@src/appV2/redesign/Navigation/ModalRoute";
import { ModalTransitionSwitch } from "@src/appV2/redesign/Navigation/ModalTransitionSwitch";

import { PageWrapper } from "../components/PageWrapper";
import { PlacementCandidateProfileModal } from "./components/PlacementCandidateProfileModal";

export const PLACEMENT_CANDIDATE_PROFILE_MODAL_PATH = "/home-v2/placement-candidate/profile";

export function PlacementCandidateModalRoutes() {
  return (
    <ThemeProvider theme={getTheme()}>
      <CssBaseline />
      <PageWrapper>
        <ModalTransitionSwitch>
          <ModalRoute
            shouldReplacePathOnBack
            closeModalPath="/home-v2/placements"
            path={PLACEMENT_CANDIDATE_PROFILE_MODAL_PATH}
            render={({ modalState }) => {
              return (
                <PlacementCandidateProfileModal
                  modalState={modalState}
                  onClose={() => {
                    modalState.closeModal();
                  }}
                />
              );
            }}
          />
        </ModalTransitionSwitch>
      </PageWrapper>
    </ThemeProvider>
  );
}
