import { isDefined } from "@clipboard-health/util-ts";
import { type Location } from "@src/appV2/lib/GoogleMaps";

interface ConstructAppleMapUrlOptions {
  location: Location;
  formattedAddress?: string;
}

/**
 * Constructs an Apple Maps URL for a given location
 * Always includes coordinates for precise marker placement
 */
export function constructAppleMapUrl(options: ConstructAppleMapUrlOptions): string {
  const { location, formattedAddress } = options;
  const baseUrl = "http://maps.apple.com";
  const searchParams = new URLSearchParams();

  searchParams.set("ll", `${location.lat},${location.lng}`);

  if (isDefined(formattedAddress)) {
    searchParams.set("address", formattedAddress);
  }

  return `${baseUrl}?${searchParams.toString()}`;
}
