import { get, patch, post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQuery, type UseQueryOptions } from "@tanstack/react-query";
import { z } from "zod";

import {
  type PlacementCandidate,
  placementCandidateSchema,
  PlacementCandidateStatus,
  placementCandidateUploadedResumeSchema,
  placementCandidateWorkerAttributeIncludedSchema,
} from "../types";
import {
  getPlacementCandidateQueryKey,
  placementCandidateApiUrl,
  transformResponseIntoPlacementCandidate,
} from "./placementCandidate.helpers";
import { placementCandidateResponseSchema } from "./useGetPlacementCandidate";
import { updatePlacementCandidateRequestSchema } from "./useUpdatePlacementCandidate";

interface UsePlacementCandidateParams {
  workerId: string;
}

const createPlacementCandidateRequestSchema = z.object({
  data: z.object({
    type: z.literal("placement-candidates"),
    attributes: z.object({ workerId: z.string() }),
  }),
});

export const createPlacementCandidateResponseSchema = z.object({
  data: placementCandidateSchema,
  included: z.array(
    z.discriminatedUnion("type", [
      placementCandidateWorkerAttributeIncludedSchema,
      placementCandidateUploadedResumeSchema,
    ])
  ),
});

async function enableDisabledPlacementCandidate(
  placementCandidate: PlacementCandidate
): Promise<PlacementCandidate> {
  if (placementCandidate.status !== PlacementCandidateStatus.DISABLED) {
    return placementCandidate;
  }

  await patch({
    url: `${environmentConfig.REACT_APP_API_MAIN_URL}/api/placement-candidates/${placementCandidate.id}`,
    data: {
      data: {
        attributes: {
          status: PlacementCandidateStatus.ONBOARDING,
        },
        id: placementCandidate.id,
        type: "placement-candidates",
      },
    },
    requestSchema: updatePlacementCandidateRequestSchema,
    responseSchema: z.string(),
  });

  return {
    ...placementCandidate,
    status: PlacementCandidateStatus.ONBOARDING,
  };
}

export function useCreateOrGetPlacementCandidate(
  params: UsePlacementCandidateParams,
  options?: UseQueryOptions<PlacementCandidate>
) {
  const { workerId } = params;
  return useQuery({
    queryKey: getPlacementCandidateQueryKey(workerId),
    queryFn: async () => {
      const response = await get({
        url: placementCandidateApiUrl,
        queryParams: {
          page: {
            size: 1,
          },
          filter: {
            "worker.id": workerId,
          },
        },
        responseSchema: placementCandidateResponseSchema,
      });

      if (Array.isArray(response.data.data) && response.data.data.length > 0) {
        const { success, data } = placementCandidateResponseSchema.safeParse(response.data);
        const placementCandidate = transformResponseIntoPlacementCandidate(
          success ? data : response.data
        );

        return await enableDisabledPlacementCandidate(placementCandidate);
      }

      const createCandidateResponse = await post({
        url: placementCandidateApiUrl,
        data: {
          data: {
            type: "placement-candidates",
            attributes: {
              workerId,
            },
          },
        },
        requestSchema: createPlacementCandidateRequestSchema,
        responseSchema: createPlacementCandidateResponseSchema,
      });

      const { success, data } = createPlacementCandidateResponseSchema.safeParse(
        createCandidateResponse.data
      );
      const placementCandidate = transformResponseIntoPlacementCandidate(
        success ? data : createCandidateResponse.data
      );

      return await enableDisabledPlacementCandidate(placementCandidate);
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.CREATE_OR_GET_PLACEMENT_CANDIDATE_FAILURE,
    },
    ...options,
  });
}
