import { isDefined } from "@clipboard-health/util-ts";
import { type Worker } from "@src/appV2/Worker/api/types";

import { PullToRefresh } from "../../lib/PullToRefresh/PullToRefresh";
import { PlacementsPage } from "../Placements/Page";
import { useCreateOrGetPlacementCandidate } from "./api/useCreateOrGetPlacementCandidate";
import { PlacementCandidateError } from "./components/PlacementCandidateError";
import { PlacementCandidateSkeleton } from "./components/PlacementCandidateSkeleton";

interface PlacementCandidatePageProps {
  worker: Worker;
}

export function PlacementCandidatePage(props: PlacementCandidatePageProps) {
  const { worker } = props;
  const {
    data: placementCandidate,
    isLoading: isPlacementCandidateLoading,
    isSuccess: isPlacementCandidateSuccess,
    refetch: refetchPlacementCandidate,
  } = useCreateOrGetPlacementCandidate(
    { workerId: worker.userId },
    { enabled: isDefined(worker.userId), staleTime: 60 * 1000 }
  );

  if (isPlacementCandidateLoading) {
    return <PlacementCandidateSkeleton />;
  }

  if (!isPlacementCandidateSuccess || !isDefined(placementCandidate.id)) {
    return (
      <>
        <PullToRefresh onRefresh={refetchPlacementCandidate} />
        <PlacementCandidateError />
      </>
    );
  }

  return <PlacementsPage placementCandidate={placementCandidate} worker={worker} />;
}
