import { environmentConfig } from "@src/appV2/environment";
import { type z } from "zod";

import {
  type PlacementCandidate,
  type PlacementCandidateUploadedResume,
  type PlacementCandidateWorkerAttributeIncluded,
} from "../types";
import { type createPlacementCandidateResponseSchema } from "./useCreateOrGetPlacementCandidate";
import { type placementCandidateResponseSchema } from "./useGetPlacementCandidate";

export const placementCandidateApiUrl = `${environmentConfig.REACT_APP_BASE_API_URL}/placement-candidates`;

export function transformResponseIntoPlacementCandidate(
  response:
    | z.infer<typeof placementCandidateResponseSchema>
    | z.infer<typeof createPlacementCandidateResponseSchema>
): PlacementCandidate {
  const placementCandidate = Array.isArray(response.data) ? response.data[0] : response.data;

  const worker = response.included.find(
    (item): item is PlacementCandidateWorkerAttributeIncluded =>
      item.type === "workers" && item.id === placementCandidate.relationships.worker.data.id
  );

  if (!worker) {
    throw new Error("Candidate details not found");
  }

  const pendingResume = response.included.find(
    (item): item is PlacementCandidateUploadedResume =>
      item.type === "uploaded-resume" &&
      item.id === placementCandidate.relationships.pendingResume?.data.id
  );

  const resume = response.included.find(
    (item): item is PlacementCandidateUploadedResume =>
      item.type === "uploaded-resume" &&
      item.id === placementCandidate.relationships.resume?.data.id
  );

  const {
    attributes: {
      status,
      introductionUrl,
      jobTypesInterested,
      shiftTypesInterested,
      preferredAreas,
    },
    id: placementCandidateId,
  } = placementCandidate;
  const {
    attributes: {
      cbhPassportDocuments,
      completedShiftsCount,
      distance,
      email,
      name,
      phone,
      profileImageUrl,
      qualification,
    },
    id: workerId,
  } = worker;

  return {
    id: placementCandidateId,
    status,
    introductionUrl,
    jobTypesInterested,
    shiftTypesInterested,
    ...(preferredAreas !== undefined && { preferredAreas }),
    worker: {
      id: workerId,
      name,
      qualification,
      ...(distance !== undefined && { distance }),
      ...(completedShiftsCount !== undefined && { completedShiftsCount }),
      ...(cbhPassportDocuments !== undefined && { cbhPassportDocuments }),
      ...(email !== undefined && { email }),
      ...(phone !== undefined && { phone }),
      ...(profileImageUrl !== undefined && { profileImageUrl }),
    },
    pendingResume: pendingResume?.attributes,
    resume: resume?.attributes,
  };
}

export function getPlacementCandidateQueryKey(workerId: string) {
  return [placementCandidateApiUrl, workerId];
}
