import { get } from "@src/appV2/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQuery, type UseQueryOptions } from "@tanstack/react-query";
import { z } from "zod";

import {
  type EmptyPlacementCandidate,
  type PlacementCandidate,
  placementCandidateSchema,
  placementCandidateUploadedResumeSchema,
  placementCandidateWorkerAttributeIncludedSchema,
} from "../types";
import {
  getPlacementCandidateQueryKey,
  placementCandidateApiUrl,
  transformResponseIntoPlacementCandidate,
} from "./placementCandidate.helpers";

interface UseGetPlacementCandidateParams {
  workerId: string;
}

export const placementCandidateResponseSchema = z.object({
  data: z.array(placementCandidateSchema),
  included: z.array(
    z.discriminatedUnion("type", [
      placementCandidateWorkerAttributeIncludedSchema,
      placementCandidateUploadedResumeSchema,
    ])
  ),
});

export function useGetPlacementCandidate(
  params: UseGetPlacementCandidateParams,
  options?: UseQueryOptions<PlacementCandidate | EmptyPlacementCandidate>
) {
  const { workerId } = params;

  return useQuery({
    queryKey: getPlacementCandidateQueryKey(workerId),
    queryFn: async () => {
      const response = await get({
        url: placementCandidateApiUrl,
        queryParams: {
          page: {
            size: 1,
          },
          filter: {
            "worker.id": workerId,
          },
        },
        responseSchema: placementCandidateResponseSchema,
      });
      if (!Array.isArray(response.data.data) || response.data.data.length === 0) {
        return {
          resume: undefined,
        };
      }

      const { success, data } = placementCandidateResponseSchema.safeParse(response.data);
      return transformResponseIntoPlacementCandidate(success ? data : response.data);
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_PLACEMENT_CANDIDATE_FAILURE,
    },
    ...options,
  });
}
