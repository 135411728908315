import { Stack, type SxProps, type Theme } from "@mui/material";

import { HourlyPayLabel } from "./HourlyPayLabel";
import { TotalPayLabel } from "./TotalPayLabel";

export interface PayInfoLabelProps {
  /**
   * The formatted total pay for the shift.
   */
  totalPay: string | undefined;
  /**
   * The formatted hourly pay for the shift.
   */
  hourlyPay: string | undefined;
  /**
   * Whether the pay data is being fetched.
   */
  isLoading?: boolean;
  /**
   * Custom styles to apply to the component.
   */
  wrapperSx?: SxProps<Theme>;
}

// TODO: Move to cbh-core as fast follow up to https://linear.app/clipboardhealth/issue/SPRTA-1362/update-shift-details-bottom-sheet
export function PayInfoLabel(props: PayInfoLabelProps) {
  const { totalPay, hourlyPay, isLoading, wrapperSx } = props;

  return (
    <Stack spacing={3} direction="row" alignItems="center" sx={wrapperSx}>
      <TotalPayLabel
        totalPay={totalPay}
        isLoading={isLoading}
        sx={(theme) => ({ color: theme.palette.text.tertiary })}
      />
      <HourlyPayLabel hourlyPay={hourlyPay} isLoading={isLoading} size="medium" />
    </Stack>
  );
}
